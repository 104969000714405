<template>
  <BaseSideMenuContainer
    title="Profiles"
    :side-menu-items="formatedProfiles"
    :active-item="profileId"
    is-sortable
    @selectItem="onItemSelected"
  >
    <b-form class="container--profile-creation profile-creation_container">
      <TitleContainer :title="`${title} - Profile Edition`">
        <button
          type="button"
          @click="$router.push(linkToPrescription)"
        >
          <b-icon icon="x" />
        </button>
      </TitleContainer>

      <ProfileCreationEditionConfiguration @selectItem="onItemSelected" />

      <BaseContainer
        title="Events List"
        border
        @dragleave.native="onDragLeaveRow"
      >
        <div
          v-if="isAppLoading"
          class="row profile-creation_events_list"
        >
          <ListHorizontal
            title="Loading"
            :loading="isAppLoading"
          />
        </div>
        <template v-else>
          <div
            v-for="event in view.events"
            :key="event.eventId"
            class="profile-creation_events_event"
          >
            <h3>{{ eventLabelsById[event.eventId] }}</h3>
            <div
              v-for="step in event.steps"
              :id="step.stepId"
              :key="step.stepId"
              class="row profile-creation_events_list"
              :class="{ 'on-drop': step.stepId === targetDropId }"
              @drop="onDrop($event, step.stepId)"
              @dragover.prevent="onDragOverRow(step.stepId)"
              @dragenter.prevent
            >
              <ListHorizontal
                :title="getStepLabel(step.stepId)"
                :class="
                  event.steps.length === 1
                    ? 'horizontal-scroll_container--only-child'
                    : ''
                "
                :loading="isAppLoading"
                :items="getStepItems(step.stepId)"
                disable-scroll
              >
                <template #item="{ item }">
                  <ItemCard
                    :item="item"
                    draggable
                    additional-actions
                    @openFlagModal="openFlagModal(item)"
                    @deleteSection="deleteSection(item, step.stepId)"
                    @dragstart="startDrag($event, item, step.stepId)"
                    @dragover.native="onDragOver($event, item)"
                    @dragleave.native="onDragLeave($event)"
                  />
                </template>
              </ListHorizontal>
            </div>
          </div>
        </template>
      </BaseContainer>

      <ProfileCreationEditionItemDrawer
        @startDrag="startDrag"
        @onDragOver="onDragOver"
        @onDragLeave="onDragLeave"
      />

      <b-modal
        id="modal-flags"
        title="List of flags"
        hide-footer
        @hide="closeFlagModal()"
      >
        <div class="modal-action">
          <label>Add flags to the section</label>
          <CriteriasMultiselect
            v-if="!isAppLoading"
            :options="formatedFlags"
            :values="selectedSectionFlags"
            @update:values="updateFlags(...arguments)"
          />
        </div>
        <div
          v-if="selectedSection"
          class="row"
        >
          <Tile
            :label="selectedSection.label"
            :details="selectedSection.details"
            :image="selectedSection.image"
            :title="selectedSection.brand"
            :description="selectedSection.description"
            container-class="col-12 tile_container--demo"
          />
        </div>
      </b-modal>
    </b-form>
  </BaseSideMenuContainer>
</template>

<script>
  import * as ProfileService from '@/service/ProfileService'
  import { formatListSameActions } from '@/service/SideMenuFormatService'

  import CriteriasMultiselect from '@/components/criterias-multiselect/CriteriasMultiselect'
  import Tile from '@/components/tile/Tile'
  import ListHorizontal from '@/components/list/ListHorizontal'
  import { PrescriptionMixin } from '@/mixins/PrescriptionMixin.js'
  import TitleContainer from '@/components/containers/TitleContainer'
  import BaseContainer from '@/components/containers/BaseContainer'
  import BaseSideMenuContainer from '@/components/containers/BaseSideMenuContainer'
  import { StepItem } from '@/types/StepItem'
  import ProfileCreationEditionItemDrawer from '@/components/prescription/ProfileCreationEditionItemDrawer'
  import ProfileCreationEditionConfiguration from '@/components/prescription/ProfileCreationEditionConfiguration.vue'

  import { mapState, mapActions, mapGetters } from 'vuex'

  import { PRESCRIPTION_WARNING_ONE_ITEM_OCCURRENCE_STEP } from '@/constants/WarningConstants'
  import ItemCard from './ItemCard'

  export default {
    name: 'ProfileCreationEdition',

    components: {
      CriteriasMultiselect,
      Tile,
      ListHorizontal,
      TitleContainer,
      BaseContainer,
      BaseSideMenuContainer,
      ItemCard,
      ProfileCreationEditionItemDrawer,
      ProfileCreationEditionConfiguration,
    },

    mixins: [PrescriptionMixin],

    // Useful on duplication.
    async beforeRouteUpdate(to, from, next) {
      next()
      await this.initProfile()
    },

    data() {
      return {
        isModified: false,
        isDeleted: false,
        draggedElement: null,
        draggedElementWeight: null,
        draggedElementSourceStep: null,
        selectedSection: null,
        selectedSectionFlags: [],
        currentStep: null,
        criteriasTypes: [
          {
            id: 'inclusiveStrictCriterias',
            condition: 'MUST',
            label: 'have these criteria',
          },
          {
            id: 'inclusiveCriterias',
            condition: 'SHOULD',
            label: 'have these criteria',
          },
          {
            id: 'exclusiveCriterias',
            condition: 'MUST NOT',
            label: 'have these criteria',
          },
        ],
        itemsTypes: [
          {
            id: 'inclusiveRootItemCriterias',
            condition: 'MUST',
            label: 'have these items',
          },
          {
            id: 'inclusiveItemCriterias',
            condition: 'SHOULD',
            label: 'have these items',
          },
          {
            id: 'exclusiveItemCriterias',
            condition: 'MUST NOT',
            label: 'have these items',
          },
        ],
        targetDropId: null,
      }
    },

    computed: {
      ...mapState({
        steps: (state) => state.prescriptions.steps,
        events: (state) => state.prescriptions.events,
        items: (state) => state.prescriptions.items,
        flags: (state) => state.prescriptions.flags,
        rawProfiles: (state) => state.prescriptions.profiles,
        view: (state) => state.prescriptions.view,
      }),

      ...mapGetters(['getStepById']),

      ...mapGetters('prescriptionProfile', ['getStepItems', 'isProfileLoaded']),

      formatedProfiles() {
        return formatListSameActions(this.rawProfiles)
      },

      loadingClass() {
        return this.isAppLoading ? 'loading' : ''
      },

      linkToPrescription() {
        return {
          name: 'prescription',
          params: { app_id: this.$route?.params?.app_id },
        }
      },

      profileId() {
        return this.$route?.params?.id
      },

      profilesIds() {
        return this.rawProfiles?.map((profile) => {
          return profile.id
        })
      },

      formatedFlags() {
        return this.flags?.map((flag) => {
          return {
            label: flag?.displayName ?? flag?.id,
            id: flag?.id,
            meta: flag?.meta,
          }
        })
      },

      eventLabelsById() {
        return (this.events || []).reduce((labelsById, event) => {
          const label = event.label || event.id

          return {
            ...labelsById,
            [event.id]: label,
          }
        }, {})
      },
    },

    watch: {
      isPrescriptionLoaded: {
        async handler(newValue) {
          if (newValue) {
            await this.initProfile()
          }
        },
        immediate: true,
      },
    },

    created() {
      this.setLoadingInformations({
        show: true,
        title: 'Loading Profile...',
        channel: 'profileedtion',
      })
    },

    destroyed() {
      this.resetProfileState()
    },

    methods: {
      ...mapActions('prescriptionProfile', [
        'loadProfileState',
        'resetProfileState',
        'createProfile',
        'addStepItem',
        'moveItemInSteps',
        'deleteStepItem',
      ]),
      onItemSelected(passingValue) {
        if (this.profilesIds.includes(passingValue?.itemId)) {
          this.$router.push({
            name: 'profile-creation-edition',
            params: {
              app_id: this.$route?.params?.app_id,
              id: passingValue?.itemId,
            },
          })
        }
      },

      /**
       * Init function where we set all the components values.
       */
      async initProfile() {
        if (!this.isPrescriptionLoaded) return

        this.appLoading(true)
        try {
          if (this.profileId) {
            await this.loadProfileState(this.profileId)
          } else {
            const newProfile = await this.createProfile()

            this.$router.replace({
              name: 'profile-creation-edition',
              params: {
                ...this.$route.params,
                id: newProfile.id,
              },
            })
          }
        } catch (error) {
          const errorMessage = (error?.message ?? error) + ' Redirecting in '
          let seconds = 5
          setInterval(() => {
            if (seconds > 0) seconds -= 1
            this.setMessageInformations({
              message: errorMessage + seconds + ' ...',
              state: 'error',
            })
          }, 1000)
          setTimeout(
            () => this.$router.push(this.linkToPrescription),
            seconds * 1000,
          )
        } finally {
          this.appLoading(false)
          this.setLoadingInformations({
            show: false,
            title: null,
            channel: 'profileedtion',
          })
        }
      },

      onDragOverRow(stepId) {
        this.targetDropId = stepId
      },

      onDragLeaveRow() {
        console.log('onDragLeaveRow')
        this.targetDropId = null
      },

      /**
       * Method launched when the drag event starts.
       *
       * @param {Object} event - The javascript event.
       * @param {Object} product - The product that is beeing dragged.
       */
      startDrag(event, product, step) {
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.effectAllowed = 'move'
        event.dataTransfer.setDragImage(event.currentTarget, 50, 50)
        this.draggedElement = product
        if (step) this.draggedElementSourceStep = step
      },

      /**
       * On item drop we update the corresponding section.
       *
       * @param {Object} event - The javascript event.
       * @param {String} stepId - The step id.
       */
      onDrop(event, stepId) {
        try {
          const step = this.getStep(stepId)
          this.currentStep = step

          const sameElementPresent = this.getStepItems(stepId).find((item) =>
            item?.sku
              ? item?.sku === this.draggedElement?.sku
              : item?.item === this.draggedElement?.item,
          )

          if (sameElementPresent && this.draggedElementSourceStep !== stepId) {
            this.targetDropId = null
            this.setMessageInformations({
              message: PRESCRIPTION_WARNING_ONE_ITEM_OCCURRENCE_STEP,
              state: 'warning',
            })
          } else {
            if (!this.draggedElement.ref) {
              this.addStepItem({
                item: new StepItem({
                  ...this.draggedElement,
                  step: stepId,
                }),
                pos: this.draggedElementWeight,
              })
            } else {
              // Update the section.
              this.draggedElement.step = this.draggedElementSourceStep
              this.moveItemInSteps({
                item: this.draggedElement,
                pos: this.draggedElementWeight,
                stepDest: stepId,
              })
            }
          }
          this.draggedElement = null
          // Remove the possible remaining indicational borders from tiles.
          event.currentTarget
            .querySelectorAll('.item-card--drop-left')
            .forEach((tile) => {
              tile.classList.remove('item-card--drop-left')
            })
          event.currentTarget
            .querySelectorAll('.item-card--drop-right')
            .forEach((tile) => {
              tile.classList.remove('item-card--drop-right')
            })
          this.targetDropId = null
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      /**
       * When we drag over items we check if we are before of after the dragged over item.
       * Like this we are able to tell if we put a more important weight or not to the item
       *
       * @param {Object} event - The javascript event.
       * @param {Object} hoveredItem - The hovered section.
       */
      onDragOver(event, hoveredItem) {
        event.dataTransfer.dropEffect = 'move'
        const hoveredElement = event.currentTarget
        const hoveredElPos = hoveredElement.getBoundingClientRect()
        const hoveredElSize = hoveredElPos.x + hoveredElPos.width

        if (this.draggedElement.item !== hoveredItem.item) {
          if (event.x > hoveredElSize - hoveredElPos.width / 2) {
            hoveredElement.classList.remove('item-card--drop-left')
            hoveredElement.classList.add('item-card--drop-right')
            this.draggedElementWeight = hoveredItem.weight + 1
          } else {
            hoveredElement.classList.remove('item-card--drop-right')
            hoveredElement.classList.add('item-card--drop-left')
            this.draggedElementWeight = hoveredItem.weight
          }
        } else {
          // It is used to avoid relaunching a request when dragging on same item.
          event.cancelBubble = true
        }
      },

      /**
       * Remove the indicational borders from tiles.
       *
       * @param {Object} event - The javascript event.
       */
      onDragLeave(event) {
        event.currentTarget.classList.remove('item-card--drop-left')
        event.currentTarget.classList.remove('item-card--drop-right')
        this.draggedElementWeight = undefined
      },

      /**
       * Proxy method to delete a section.
       *
       * @param {Object} item - The section to delete.
       */
      async deleteSection(item, stepId) {
        try {
          await this.deleteStepItem({ item, stepId })
        } catch (error) {
          this.handleErrors({ error })
        }
      },

      /**
       * Proxy method to get the step by it's id.
       *
       * @param {String} stepId - The step id.
       */
      getStep(stepId) {
        return this.getStepById(stepId) ?? []
      },

      /**
       * Proxy method to get the step's label
       *
       * @param {String} stepId - The step id.
       */
      getStepLabel(stepId) {
        const step = this.steps?.find((step) => stepId === step?.id)
        return step?.label ?? stepId
      },

      /**
       * Function triggered on the flag click.
       *
       * @param {Object} section - The section to update.
       */
      openFlagModal(section) {
        this.$bvModal.show('modal-flags')
        this.selectedSection = section
        if (
          Object.prototype.hasOwnProperty.call(section, 'flags') &&
          section.flags &&
          section.flags.length > 0
        ) {
          this.selectedSectionFlags = ProfileService.normalizeFlags(
            section.flags,
          )
        }
      },

      /**
       * Update the values of flags' section.
       *
       * @param {Array} flags - An array of flags.
       */
      updateFlags(flags) {
        this.isModified = true
        this.selectedSectionFlags = flags
        ProfileService.updateFlagsForSection(this.selectedSection, flags)
      },

      /**
       * Empty the variables linked to the flag modal.
       */
      closeFlagModal() {
        this.selectedSection = null
        this.selectedSectionFlags = []
      },
    },
  }
</script>

<style lang="scss" scoped>
  .profile-creation_container {
    padding: 0 0 20px 15px;

    padding-bottom: 230px;
  }

  .profile-creation_events_event {
    position: relative;
    margin: 0 0 30px;
    padding-left: 40px;

    &:after {
      content: '';
      background: var(--primary);
      height: 100%;
      width: 3px;
      position: absolute;
      top: 0;
      left: 10px;
      z-index: -1;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    h3 {
      text-transform: uppercase;
      position: absolute;
      letter-spacing: 3px;
      top: 50%;
      left: 0;
      margin-left: -7px;
      padding: 0 10px;
      transform: rotate(270deg) translateX(-50%);
      transform-origin: 0 0;
      background: var(--app-background-color);
      color: var(--primary);
    }
  }

  .profile-creation_events_list {
    margin: 10px 0;
    border: 1px solid var(--primary);

    .horizontal-scroll_container {
      transition: background 0.2s ease-in-out;
    }

    &.on-drop {
      .horizontal-scroll_container {
        background: var(--selected-color-drop-zone);
      }
    }
  }
</style>
