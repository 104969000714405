<template>
  <div
    class="profile-creation_item-drawer"
    :class="isListItemOpen ? 'profile-creation_item-drawer--open' : ''"
  >
    <button
      id="open-drawer-button"
      type="button"
      @click="isListItemOpen = !isListItemOpen"
    >
      {{ isListItemOpen ? 'CLOSE' : 'OPEN' }} ITEM LIST
      <b-icon :icon="isListItemOpen ? 'chevron-down' : 'chevron-up'" />
    </button>
    <ListHorizontal
      v-if="isListItemOpen"
      :loading="isAppLoading"
      :items="filteredItems"
    >
      <template #action>
        <b-input-group
          v-if="isListItemOpen"
          size="sm"
        >
          <b-input-group-prepend is-text>
            <b-icon icon="search" />
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Search product by name, EAN or brand"
            class="profile-creation_item-search_input"
          />
          <b-input-group-append>
            <b-button
              :variant="excludeSelected ? 'primary' : 'secondary'"
              @click="excludeSelected = !excludeSelected"
              >Exclude selected items</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </template>

      <template #item="{ item }">
        <ItemCard
          :item="item"
          draggable
          @dragstart="$emit('startDrag', $event, item)"
          @dragover="$emit('onDragOver', $event, item)"
          @dragleave="$emit('onDragLeave', $event)"
          @drop="$emit('onDragEnd', $event)"
        />
      </template>
    </ListHorizontal>
  </div>
</template>

<script>
  import ListHorizontal from '@/components/list/ListHorizontal'
  import { mapActions, mapState } from 'vuex'

  import ItemCard from './ItemCard'

  export default {
    name: 'ProfileCreationEditionItemDrawer',

    components: {
      ListHorizontal,
      ItemCard,
    },

    data() {
      return {
        excludeSelected: true,
        isListItemOpen: false,
        filter: '',
      }
    },

    computed: {
      ...mapState({
        items: (state) => state.prescriptions.items,
        steps: (state) => state.prescriptions.view.steps,
      }),

      filteredItems() {
        if (!this.filter) return this.items.filter((item) => item)
        if (this.items.length === 0) return []

        const filter = this.filter.trim().toLowerCase()
        let preFilter = this.items

        // Only exclude selected items if the user wants to.
        if (this.excludeSelected) {
          const existingProducts = this.steps
            .map((step) => {
              return step.items
            })
            .flat()
          preFilter = this.items.filter((item) => {
            return (
              item && !existingProducts.find((prod) => prod.item === item.item)
            )
          })
        }

        return preFilter.filter((item) => {
          if (!item) return false

          const fields = [item.label, item.brand, item.sku]

          return fields
            .map((f) => f?.toLowerCase())
            .some((value) => value?.includes(filter))
        })
      },
    },

    methods : {
      ...mapActions({
        getItems: 'prescriptions/getItems',
      }),

    },

    onMounted() {
      this.getItems()
    },

    watch: {
      items: {
        handler(v) {
          if (v.length > 0) {
            this.isListItemOpen = true
          }
        },
        immediate: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .profile-creation_item-drawer {
    position: fixed;
    left: 0;
    bottom: -222px;
    padding: 5px 0;
    height: 210px;
    width: 100%;
    background: var(--app-background-color);
    overflow: hidden;
    border-top: 3px solid var(--primary);
    transition: all 0.2s ease-in-out;
    z-index: 2;

    &--open {
      bottom: 0;

      #open-drawer-button {
        bottom: 210px;
      }
    }
  }

  #open-drawer-button {
    width: 250px;
    height: 25px;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    border: none;
    background: var(--primary);
    color: white;
    transition: all 0.2s ease-in-out;
    outline: none;

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border-bottom: 25px solid var(--primary);
    }

    &:after {
      right: -25px;
      left: auto;
      border-right: 25px solid transparent;
    }

    &:before {
      right: auto;
      left: -25px;
      border-left: 25px solid transparent;
    }
  }
</style>
