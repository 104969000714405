import db from '@/firebase/firestore'
import Functions from '@/firebase/functions'
import { DocumentResult } from '@/models'
import * as FirebaseService from '@/service/FirebaseService'

import { Profile, profileConverter } from '@/types/Profile'
import { StepItem, stepItemConverter } from '@/types/StepItem'
import {
  CollectionReference,
  QuerySnapshot,
  QueryDocumentSnapshot,
} from '@firebase/firestore-types'

import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '..'

export type StepsItems = {
  [key: string]: StepItem[]
}

export type PrescriptionProfileState = {
  profile: Profile
  profileSectionsRef: CollectionReference | null
  stepsItems: StepsItems
  unsubscribeProfile: () => void | null
  unsubscribeSections: () => void | null
}

const initialState: PrescriptionProfileState = {
  profile: new Profile(),
  profileSectionsRef: null,
  stepsItems: {},
  unsubscribeProfile: null,
  unsubscribeSections: null,
}

const state: PrescriptionProfileState = { ...initialState }

const mutations: MutationTree<PrescriptionProfileState> = {
  /**
   * Generic mutation.
   *
   * @function SET_PRESCRIPTION_PROFILE_STATE_PROPERTY
   * @param {Object} state - The state of the prescription profile.
   * @param {Object} payload - The payload with the property to update and the value.
   */
  SET_PRESCRIPTION_PROFILE_STATE_PROPERTY: (
    state: PrescriptionProfileState,
    payload: { property: string; value: any },
  ) => {
    state[payload.property] = payload.value
  },

  /**
   * Reset prescription profile state.
   *
   * @function resetPrescriptionState
   * @param {Object} state - The state of the prescription profile.
   */
  RESET_PRESCRIPTION_PROFILE_STATE: (state: PrescriptionProfileState) => {
    if (state.unsubscribeProfile) {
      state.unsubscribeProfile()
    }

    if (state.unsubscribeSections) {
      state.unsubscribeSections()
    }

    state = Object.assign(state, initialState)
  },

  SET_PROFILE_ATTRIBUTE: (
    state: PrescriptionProfileState,
    payload: { key: keyof Profile; value: any },
  ) => {
    Vue.set(state.profile, payload.key, payload.value)
  },

  SET_PROFILE_ACTIVE: (state, value: boolean) => {
    state.profile.meta.active = value
  },
}

const actions: ActionTree<PrescriptionProfileState, RootState> = {
  /**
   * Resets the Prescription Profile state.
   * @function resetProfileState
   */
  resetProfileState({ commit }) {
    commit('RESET_PRESCRIPTION_PROFILE_STATE')
  },

  /**
   * Create a new profile.
   *
   * @function createProfile
   * @param '{Object}' state - The prescriptions state.
   */
  async createProfile({ commit, rootState, rootGetters }) {
    const newDocRef = FirebaseService.addGeneratedDoc(
      rootGetters.prescriptionPath + '/profiles',
    )

    const profile = new Profile({
      id: newDocRef.id,
      ref: newDocRef.path,
      label: `Profile #${rootState.prescriptions.profiles.length + 1}`,
    })

    await FirebaseService.addDocToCollection(
      rootGetters.prescriptionPath,
      'profiles',
      profile,
      profileConverter,
      newDocRef.id,
    )

    commit('SET_PRESCRIPTION_PROFILE_STATE_PROPERTY', {
      property: 'profile',
      value: profile,
    })
    return profile
  },

  /**
   * @function addStepItem
   * @param ActionContext
   * @param {Object} payload
   * @param {StepItem}	payload.item - The item to add to the step
   * @param {Number} payload.pos - The position of the item to insert in the step
   */
  async addStepItem(
    { getters, state, dispatch, rootState },
    { item, pos }: { item: StepItem; pos?: number },
  ) {
    const items = getters.getStepItems(item.step)

    item.weight = getItemWeight(items, pos)

    await updateStepWeights(items, item.weight, items.length, true)

    delete item.meta
    await FirebaseService.addDocToCollection(
      state.profile.ref,
      'sections',
      item,
      stepItemConverter,
      null,
    )
    dispatch(
      'setProfilesByItems',
      { appRouteId: rootState?.approutes?.currentRoute?.id, force: true },
      { root: true },
    )
  },

  /**
   * @function moveItemInSteps
   * @param ActionContext
   * @param {Object} payload
   * @param {StepItem} payload.item- The item to add to the step
   * @param {String} payload.stepId - The id of the step where the item should be added
   * @param {String} payload.stepDest - The position of the item to insert in the step
   */
  async moveItemInSteps(
    { getters },
    {
      item,
      pos,
      stepDest,
    }: { item: StepItem; pos?: number; stepDest?: string },
  ) {
    if (!stepDest) throw new Error('Invalid destination step to move an item')

    if (item.step === stepDest) {
      const items = getters.getStepItems(item.step)
      let newPos = isNaN(pos) ? items.length : pos
      if (item.weight < pos) newPos -= 1
      const newItems = items.filter(({ ref }) => ref !== item.ref)
      newItems.splice(newPos, 0, item)

      await Promise.all(
        newItems.map((i, index) =>
          FirebaseService.updateRef(i.ref, {
            weight: index,
          }),
        ),
      )
    } else {
      const itemsOldStep = getters.getStepItems(item.step)
      await updateStepWeights(
        itemsOldStep.filter(({ ref }) => ref !== item.ref),
        item.weight,
        itemsOldStep?.length,
        false,
      )

      const itemsNewStep = getters.getStepItems(stepDest)

      item.weight = getItemWeight(itemsNewStep, pos)

      await FirebaseService.updateRef(item.ref, {
        step: stepDest,
        weight: item.weight,
      })

      await updateStepWeights(
        itemsNewStep,
        item.weight,
        itemsNewStep.length,
        true,
      )
    }
  },

  async deleteStepItem(
    { getters, dispatch, rootState },
    { item, stepId }: { item: StepItem; stepId: string },
  ) {
    const items = getters.getStepItems(stepId)

    await updateStepWeights(
      items.filter(({ ref }) => ref !== item.ref),
      item.weight,
      items?.length,
      false,
    )

    await FirebaseService.deleteRef(item.ref)
    dispatch(
      'setProfilesByItems',
      { appRouteId: rootState?.approutes?.currentRoute?.id, force: true },
      { root: true },
    )
  },

  async loadProfileState(
    { commit, dispatch, rootGetters, state },
    profileId: string,
  ) {
    if (!rootGetters.prescriptionPath)
      throw new Error('No prescription path found')
    if (state.unsubscribeProfile) state.unsubscribeProfile()

    const pathPrescription = `${rootGetters.prescriptionPath}/profiles/${profileId}`

    const unsubscribeProfile = db
      .doc(pathPrescription)
      .withConverter(profileConverter)
      .onSnapshot((snap) => {
        commit('SET_PRESCRIPTION_PROFILE_STATE_PROPERTY', {
          property: 'profile',
          value: snap.data(),
        })
      })

    commit('SET_PRESCRIPTION_PROFILE_STATE_PROPERTY', {
      property: 'unsubscribeProfile',
      value: unsubscribeProfile,
    })
    commit('SET_PRESCRIPTION_PROFILE_STATE_PROPERTY', {
      property: 'profileSectionsRef',
      value: db.collection(`${pathPrescription}/sections/`),
    })

    dispatch('getProfileSections')
  },

  async deletePrescriptionProfile({ commit, state }) {
    await FirebaseService.deleteRef(state.profile.ref)
    commit('RESET_PRESCRIPTION_PROFILE_STATE')
  },

  /**
   * Get all the sections for a specfic profile.
   * Then store them in the 'sections' state.
   * Watch the changes mades thanks to firebase onSnapshot method.
   *
   */
  getProfileSections({ commit, state }) {
    if (state.unsubscribeSections) state.unsubscribeSections()

    const unsubscribeSections = state.profileSectionsRef
      .orderBy('weight')
      .withConverter(stepItemConverter)
      .onSnapshot(async (docs: QuerySnapshot) => {
        const stepItems = docs.docs.reduce(
          (acc, doc: QueryDocumentSnapshot) => {
            const section = doc.data()

            if (!acc[section.step]) acc[section.step] = []
            acc[section.step].push(section)
            return acc
          },
          {},
        )

        commit('SET_PRESCRIPTION_PROFILE_STATE_PROPERTY', {
          property: 'stepsItems',
          value: stepItems,
        })
      })

    commit('SET_PRESCRIPTION_PROFILE_STATE_PROPERTY', {
      property: 'unsubscribeSections',
      value: unsubscribeSections,
    })
  },
  /**
   * Proxy method to update the fields of a reference.
   *
   */
  async updateProfile(
    { state, commit },
    payload: { key: keyof Profile; value: any },
  ) {
    if (!state.profile || !payload.key) {
      return
    }

    commit('SET_PROFILE_ATTRIBUTE', payload)

    await FirebaseService.updateRef(state.profile.ref, {
      [payload.key]: payload.value,
    })
  },

  async deleteProfile({ state, commit }) {
    await FirebaseService.deleteRef(state.profile?.ref)
    commit('RESET_PRESCRIPTION_PROFILE_STATE')
  },

  async updateProfileActive({ state, commit }, value: boolean) {
    commit('SET_PROFILE_ACTIVE', value)
    await FirebaseService.updateActive(state.profile.ref, value)
  },

  async duplicateProfile({ state }): Promise<DocumentResult> {
    const newDocument = await Functions.duplicateDocument(state.profile.ref)
    return newDocument
  },
}

const getters: GetterTree<PrescriptionProfileState, RootState> = {
  itemsWeights: (state) => {
    return Object.entries(state.stepsItems).map(([step, items]) => {
      return {
        step,
        w: items.map((item) => item.weight),
      }
    })
  },

  /**
   * Returns the items for a specified step
   * @function getItemsForStep
   * @param {Object} getters - The getters of the prescription profile.
   * @param {String} stepId - The id of the step
   */
  getItemsForStep: (state) => (stepId: string) => {
    return state.stepsItems[stepId] ?? []
  },

  isProfileActive: (state: PrescriptionProfileState) => {
    return state.profile.meta.active == true
  },

  // -- PROFILE'S CRITERIA GETTERS --

  /**
   * @function inclusiveRootItemCriterias
   * @param {Object} state - The state of the prescription profile.
   * @param {Object} rootGetters - Getters function from other modules.
   */
  inclusiveRootItemCriterias: (state, _getters, _rootState, rootGetters) => {
    return state.profile.inclusiveRootItemCriterias.map((id) => {
      return rootGetters.getItemById(id)
    })
  },

  /**
   * @function inclusiveItemCriterias
   * @param {Object} state - The state of the prescription profile.
   * @param {Object} rootGetters - Getters function from other modules.
   */
  inclusiveItemCriterias: (state, _getters, _rootState, rootGetters) => {
    return state.profile.inclusiveItemCriterias.map((id) => {
      return rootGetters.getItemById(id)
    })
  },

  /**
   * @function exclusiveItemCriterias
   * @param {Object} state - The state of the prescription profile.
   * @param {Object} rootGetters - Getters function from other modules.
   */
  exclusiveItemCriterias: (state, _getters, _rootState, rootGetters) => {
    return state.profile.exclusiveItemCriterias.map((id) => {
      return rootGetters.getItemById(id)
    })
  },

  /**
   * @function inclusiveCriterias
   * @param {Object} state - The state of the prescription profile.
   * @param {Object} rootGetters - Getters function from other modules.
   */
  inclusiveCriterias: (state, _getters, _rootState, rootGetters) => {
    return state.profile.inclusiveCriterias.map((criteria) => {
      return rootGetters.getCriteriaById(criteria)
    })
  },

  /**
   * @function inclusiveStrictCriterias
   * @param {Object} state - The state of the prescription profile.
   * @param {Object} rootGetters - Getters function from other modules.
   */
  inclusiveStrictCriterias: (state, _getters, _rootState, rootGetters) => {
    return state.profile.inclusiveStrictCriterias.map((criteria) => {
      return rootGetters.getCriteriaById(criteria)
    })
  },

  /**
   * @function exclusiveCriterias
   * @param {Object} state - The state of the prescription profile.
   * @param {Object} rootGetters - Getters function from other modules.
   */
  exclusiveCriterias: (state, _getters, _rootState, rootGetters) => {
    return state.profile.exclusiveCriterias.map((criteria) => {
      return rootGetters.getCriteriaById(criteria)
    })
  },

  getStepItems: (state) => (stepId: string) => {
    return state.stepsItems[stepId] ?? []
  },

  isProfileLoaded: (state) => {
    return state.profile.ref && Object.keys(state.stepsItems).length > 0
  },
}

async function updateStepWeights(
  items: StepItem[] = [],
  start = 0,
  end = 0,
  increase: boolean,
) {
  items.slice(start, end).map((stepItem) => {
    const newWeight = increase ? stepItem.weight + 1 : stepItem.weight - 1
    return FirebaseService.updateRef(
      stepItem.ref,
      {
        weight: newWeight,
      },
      false,
    )
  })
}

function getItemWeight(items: StepItem[], pos: number | null) {
  if (isNaN(pos) || pos === null) {
    return items.length
  } else if (pos < 0) {
    return 0
  }

  return pos
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
