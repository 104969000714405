
  import ProductAPIConfigurationSelectTenant from '@/components/product-api-config/ProductAPIConfigurationSelectTenant.vue'
  import TitleContainer from '@/components/containers/TitleContainer.vue'
  import Multiselect from '@/components/form/Multiselect.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import List from '@/components/list/List.vue'
  import Tile from '@/components/tile/Tile.vue'
  import groupBy from 'lodash/fp/groupBy'
  import { RESOURCES } from '@/service/ResourceService'

  const reduceDisplayName = (
    elements: { id: string; displayName: string }[],
  ): Record<string, string> => {
    if (!elements) return {}

    return elements.reduce((indexed, { id, displayName }) => {
      indexed[id] = displayName
      return indexed
    }, {})
  }

  export default {
    name: 'CatalogConfigurationList',
    components: {
      ProductAPIConfigurationSelectTenant,
      TitleContainer,
      Multiselect,
      List,
      Tile,
    },
    props: {
      listEmpty: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        country: null,
        customer: null,
        environment: null,
        environments: [
          {
            displayName: 'Staging',
            id: 'staging',
          },
          {
            displayName: 'Production',
            id: 'production',
          },
        ],
        searchInput: '',
        additionalActions: true,
        tenant: this.$route.params.tenant,
      }
    },
    computed: {
      ...mapState({
        customers: (state: { customers: any }) => state.customers,
        countries: (state: { bmdmCountries: any }) => state.bmdmCountries,
        fetchedResources: (state) => state.fetchedResources,
        appRouteID: (state) => state.approutes.currentRoute.id,
      }),
      ...mapGetters('scopes', ['computedScope']),
      ...mapGetters('products', [
        'catalogConnectors',
        'catalogConnectorsLoading',
        'filteredCatalogConnectors',
      ]),
      title() {
        return this.$route.meta?.displayName ?? 'Catalog Configuration'
      },
      customerDisplayName() {
        return reduceDisplayName(this.customers)
      },
      groupByCustomerCode() {
        return groupBy('customerCode')(this.filteredCatalogs)
      },
      filteredCatalogs() {
        return this.filteredCatalogConnectors({
          customer: this.customer?.id,
          country: this.country?.id,
          environment: this.environment?.id,
          searchInput: this.searchInput,
        })
      },
    },
    async mounted() {
      await this.loadData()
    },
    watch: {
      '$route.params.tenant'() {
        this.loadCatalog()
      },
    },
    methods: {
      ...mapActions(['loadBmdmCountries', 'loadResources', 'bindAppRoutes']),
      ...mapActions('products', ['loadCatalogConnectors']),
      async loadCatalog() {
        const tenant = this.$route.params.tenant

        if (!tenant && this.computedScope.tenants.options.length > 0) {
          this.$router.push({
            name: this.$route.name,
            params: {
              ...this.$route.params,
              tenant: this.computedScope.tenants.options[0].id,
            },
          })
          return
        }

        if (
          !this.computedScope.tenants.options
            .map((tenant) => tenant.id)
            .includes(tenant)
        ) {
          return
        }

        try {
          this.appLoading(true)
          const customerIds = this.customers.map((customer) => customer.id)
          this.loadCatalogConnectors({
            tenant,
            customerIds,
          })
        } catch (error) {
          this.handleErrors(error)
        } finally {
          this.appLoading(false)
        }
      },

      clearFilters() {
        this.customer = null
        this.country = null
        this.environment = null
        this.searchInput = ''
      },

      async loadData() {
        try {
          this.appLoading(true)
          await Promise.all([
            this.loadResources({
              resourceNames: [RESOURCES.CUSTOMERS],
            }),
            this.loadBmdmCountries(),
            this.bindAppRoutes(),
          ])
          await this.loadCatalog()
        } catch (error) {
          this.handleErrors(error)
        } finally {
          this.appLoading(false)
        }
      },
    },
  }
