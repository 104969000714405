<template>
  <BaseContainer>
    <b-form-group
      v-for="(value, fieldName, index) in formData.configuration"
      :key="`create-route-configuration-field-${fieldName}`"
      :label="capitalize(fieldName)"
      :label-for="`app-route-${fieldName}`"
      required
      :invalid-feedback="`${fieldName} is required`"
    >
      <b-form-select
        :id="`app-route-${fieldName}`"
        v-model="formData.configuration[fieldName]"
        :options="getOptions(fieldName)"
        :disabled="isFieldDisabled(index)"
        value-field="id"
        :text-field="
          fieldName === 'version'
            ? 'meta.version'
            : fieldName === 'copyFrom'
            ? 'id'
            : 'displayName'
        "
        required
        @change="handleConfigurationChanges(index)"
      >
        <template #first>
          <b-form-select-option
            :value="null"
            disabled
            >Select {{ fieldName }}</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
  </BaseContainer>
</template>

<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { firstCharacterToUppercase } from '@/utils/StringHelpers'
  import BaseContainer from '@/components/containers/BaseContainer'
  import { RESOURCES } from '@/service/ResourceService'

  const fieldsConfiguration = [
    'app',
    'version',
    'type',
    'tenant',
    'customer',
    'country',
    'touchpoint',
    'env',
  ]
  const initialData = {
    configuration: {
      ...fieldsConfiguration.reduce((accumulator, field) => {
        return {
          ...accumulator,
          [field]: null,
        }
      }, {}),
    },
  }

  export default {
    name: 'CreateRouteConfiguration',

    components: {
      BaseContainer,
    },

    data() {
      return {
        formData: JSON.parse(JSON.stringify(initialData)),
        isDuplicate: false,
      }
    },

    computed: {
      ...mapGetters(['getAppVersions', 'getCustomers', 'listRoutes']),

      ...mapState({
        apps: (state) => state.applications,
        tenants: (state) => state.tenants,
        countries: (state) => state.countries,
        touchpoints: (state) => state.touchpoints,
        envs: (state) => state.envs,
        types: (state) => state.types,
        fetchedResources: (state) => state.fetchedResources,
      }),

      routes() {
        return this.listRoutes(
          this.formData?.configuration?.app,
          this.formData?.configuration?.version,
        )
      },

      versions() {
        return this.getAppVersions(this.formData?.configuration?.app)
      },

      customers() {
        return this.formData?.configuration?.type?.length > 0 
          ? this.getCustomers( this.isDuplicate ? this.$attrs?.value?.configuration.tenant : this.formData.configuration.tenant)
          : []
      },
    },

    created() {
      if (this.$attrs?.value?.configuration?.copyFrom) {
        this.isDuplicate = true
      }
      if (this.$attrs?.value?.configuration)
        this.formData.configuration = this.$attrs.value.configuration
    },

    async mounted() {
      await Promise.all([
        this.loadResources({
          resourceNames: [
            RESOURCES.TOUCHPOINTS,
            RESOURCES.TYPES,
            RESOURCES.ENVIRONMENTS,
            RESOURCES.TENANTS,
            RESOURCES.COUNTRIES,
            RESOURCES.CUSTOMERS,
            RESOURCES.APPLICATIONS,
            RESOURCES.APP_VERSIONS,
          ],
        }),
        this.bindAppRoutes(),
      ])
    },

    methods: {
      ...mapActions(['loadResources', 'bindAppRoutes']),

      capitalize(string) {
        if (string === 'app') return 'Application:'
        if (string === 'env') return 'Environment:'
        if (string === 'copyFrom') return 'Copy From:'
        return firstCharacterToUppercase(string) + ':'
      },

      getFieldNameInPlural(fieldName) {
        if (fieldName.endsWith('y')) return `${fieldName.slice(0, -1)}ies`
        return `${fieldName}s`
      },

      getOptions(fieldName) {
        const fieldFormated =
          fieldName === 'copyFrom'
            ? 'routes'
            : this.getFieldNameInPlural(fieldName)
        return this[fieldFormated]
      },

      isFieldDisabled(index) {
        const fieldName = fieldsConfiguration[index]
        const resourceName =
          fieldName === 'app'
            ? 'applications'
            : fieldName === 'version'
            ? 'appversions'
            : fieldName && this.getFieldNameInPlural(fieldName)

        return (
          this.isAppLoading ||
          (resourceName && !this.fetchedResources[resourceName]) ||
          (!this.formData.configuration[
            Object.keys(this.formData.configuration)[index - 1]
          ] &&
            index !== 0) ||
          (fieldName === 'tenant' && this.isDuplicate)
        )
      },

      handleConfigurationChanges(index) {
        const formDataKeys = Object.keys(this.formData.configuration)
        formDataKeys
          .slice(index + 1)
          .forEach((key) => (this.formData.configuration[key] = null))

        if (this.formData.configuration['version'] && this.$attrs.value.setup) {
          const version = this.versions.find(
            ({ id }) => id === this.formData.configuration['version'],
          )
          if (version && version.prescription) {
            this.$attrs.value.setup.firedefaultcontent.appprescriptions = null
          }
        }
        this.$emit('input', {
          ...this.$attrs.value,
          configuration: this.formData.configuration,
        })
      },
    },
  }
</script>
