import Vue from 'vue'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import { BHProductDTO, ProductStats } from '@/dtos/BHProduct.dto'
import { LookDataDto } from '@/dtos/LookData.dto'
import {
  DetailedItems,
  DocumentResult,
  Group,
  PrescriptionDataForm,
  Role,
  Scope,
} from '@/models'
import ApiService, { HttpResponseStandard } from '@/service/ApiService'
import { uploadUserProfilePicture } from '@/service/FirebaseService'
import { generatePassword } from '@/service/FormsService'
import { FileChange } from '@/store/modules/approutes'
import { Collections } from '@/store/modules/appversions'
import { Item } from '@/types/Item'
import { User } from '@/types/User'
import Auth from './auth'
import {
  BulkProductsPayload,
  CatalogConfigurationWithBrandApi,
  CatalogConfigurationWithoutBrandApi,
} from '@/dtos/BHApiv3.dto'
import {
  BeautyHubTenant,
  GetProductsByCatalogResult,
  GetProductsByCatalogResultSchema,
} from './schemas'
import { CatalogConnector } from '@/store/modules/products'

const apiService = new ApiService()

export default class Functions {
  /**
   * Get the items set in Airtable for a specific instance and base.
   *
   * @param {String} appRouteId - The appRoute id to get the info from.
   * @return Promise<{Array}> - Return an array with all formatted items from Airtable.
   */
  static async getItemsFromAirtable(appRouteId: string) {
    if (!appRouteId) throw new Error('Missing appRouteId parameter.')

    const requestPayload = {
      functionName: 'getAirtableItems',
      method: 'GET',
      parameters: { appRouteId },
    }

    const payload = await apiService.cloudFunctionsBase<{
      airtableItems: Item[]
    }>(requestPayload)
    return payload?.airtableItems
  }

  /**
   * Get a detailed list of items from Airtable and Firecamp.
   *
   * @param {String} appRouteId - The appRoute id to get the info from.
   * @return Promise<Object> - Return an object with all formatted items from Airtable.
   */
  static async getDetailedItemsList(
    appRouteId: string,
  ): Promise<DetailedItems> {
    if (!appRouteId) throw new Error('Missing appRouteId parameter.')

    const requestPayload = {
      functionName: 'getDetailedItemsList',
      method: 'GET',
      parameters: { appRouteId },
    }
    const payload = await apiService.cloudFunctionsBase<{
      detailedItems: DetailedItems
    }>(requestPayload)

    return payload?.detailedItems
  }

  /**
   * Import multiple items from Airtable.
   *
   * @async
   * @param {Object} parameters - GET Methods parameters.
   * @return Return the imported items information.
   */
  static async importItemsFromAirtable(parameters: {
    appRouteId: string
    apiKey?: string
    overwrite?: boolean
  }) {
    if (!parameters) throw new Error('Parameters are not set.')

    const requestPayload = {
      functionName: 'importItemsFromAirtable',
      method: 'GET',
      parameters: parameters,
    }

    return apiService.cloudFunctionsBase<{
      itemsImported: Item[]
      totalItemsImported: number
    }>(requestPayload)
  }

  /**
   * Create a user in collection users and in Firebase Authentication.
   *
   * @async
   * @param {Object} user - The user body.
   * @return Return the created user response information.
   */
  static async createFirebaseUser(
    user: User & { password: string },
    needSendPasswordResetEmail: boolean,
    profilePicture: File,
  ) {
    if (!user) throw new Error('User not set.')

    // Generate a random password.
    const generatedPassword = generatePassword()
    user.password = generatedPassword

    const requestPayload = {
      functionName: 'accountCreation',
      method: 'POST',
      body: user,
    }

    // User creation.
    const createdUserInfo = await apiService.cloudFunctionsBase<{ user: User }>(
      requestPayload,
    )
    // Send a mail to reset user password.
    if (needSendPasswordResetEmail) {
      await Auth.sendPasswordResetEmail(user.email)
    }

    if (profilePicture) {
      const userId = createdUserInfo?.user?.id
      const picturePath = await uploadUserProfilePicture(userId, profilePicture)
      await Functions.updateFirebaseUser({
        uid: userId,
        profilePic: picturePath,
      })
    }

    return createdUserInfo
  }

  /**
   * Update a user in users collection and in Firebase Authentication.
   *
   * @async
   * @param {Object} user - The user body.
   * @return Return the updated user response information.
   */
  static async updateFirebaseUser(
    user: { uid: string; email?: string; profilePic: string },
    sendPasswordResetEmail?: boolean,
  ) {
    if (!user) throw new Error('User not set.')

    const requestPayload = {
      functionName: 'accountUpdate',
      method: 'POST',
      body: user,
    }

    // User update.
    const updatedUserInfo = await apiService.cloudFunctionsBase<{ user: User }>(
      requestPayload,
    )

    // Send a mail to reset user password.
    if (sendPasswordResetEmail) {
      await Auth.sendPasswordResetEmail(user.email)
    }

    return updatedUserInfo
  }

  /**
   * Delete a user.
   *
   * @async
   * @param {String} uid - The user's uid.
   * @return
   */
  static async deleteUser(uid: string) {
    if (!uid) throw new Error("Missing user's uid")

    const requestPayload = {
      functionName: 'accountDeletion',
      method: 'GET',
      parameters: { uid },
    }

    return await apiService.cloudFunctionsBase<{ message: string }>(
      requestPayload,
    )
  }

  /**
   * Get the list of users.
   * Two outputs, usersCollectionList for all users presents in users collections
   * And usersNotIncollection for only Firebase registered users.
   *
   * @async
   * @return Return both outputs.
   */
  static async getUsersList(getWaitingUsers = true) {
    const requestPayload = {
      functionName: 'getUsersList',
      method: 'GET',
      parameters: {
        getWaitingUsers,
      },
    }

    return await apiService.cloudFunctionsBase(requestPayload)
  }

  /**
   * Get the list of roles.
   * Roles with equal or less permissions.
   *
   * @async
   * @return Return the filtered roles.
   */
  static async getUser() {
    const requestPayload = {
      functionName: 'getUser',
      method: 'GET',
    }

    return await apiService.cloudFunctionsBase<{ user: User }>(requestPayload)
  }

  /**
   * Get the list of roles.
   * Roles with equal or less permissions.
   *
   * @async
   * @return Return the filtered roles.
   */
  static async getRolesList() {
    const requestPayload = {
      functionName: 'getRolesList',
      method: 'GET',
    }

    return await apiService.cloudFunctionsBase<{ filteredRoles: Role[] }>(
      requestPayload,
    )
  }

  /**
   * Get the list of scopes.
   * Filtered by scopes included in the user's scope
   *
   * @async
   * @return  Return the filtered scopes.
   */
  static async getScopesList() {
    const requestPayload = {
      functionName: 'getScopesList',
      method: 'GET',
    }

    return await apiService.cloudFunctionsBase<{ filteredScopes: Scope[] }>(
      requestPayload,
    )
  }

  /**
   * Get the list of groups.
   * Filtered by groups with scope included in the user's scope and a role with equal or less permissions.
   *
   * @async
   * @return Return both outputs.
   */
  static async getGroupsList() {
    const requestPayload = {
      functionName: 'getGroupsList',
      method: 'GET',
    }

    return await apiService.cloudFunctionsBase<{ filteredGroups: Group[] }>(
      requestPayload,
    )
  }

  /**
   * Upload new assets to Azure repository.
   *
   * @async
   * @param {String} appRouteId - The add route id.
   * @param {Array} filesToUpload - The files to upload to instance.
   * @return API returns.
   */
  static async uploadAssets(
    appRouteId: string,
    filesToUpload: { file: File; path: string }[],
  ) {
    const body = new FormData()
    filesToUpload.forEach((file, index) => {
      body.append('asset', file.file, `asset[${index}]`)
      body.append(`path[${index}]`, file.path)
    })

    const requestPayload = {
      functionName: 'uploadAssets',
      method: 'POST',
      parameters: { appRouteId },
      body,
    }

    return await apiService.cloudFunctionsBase<{
      uploadedFileCount: number
      uploaded: string[]
      failed: { path: string; reason: string }[]
    }>(requestPayload)
  }

  /**
   * Copy assets from one instance to another.
   *
   * @async
   * @param {String} sourceAppRouteId - The source add approute id.
   * @param {String} destinationAppRouteId - The destination add approute id.
   * @return API returns.
   */
  static async copyAssets(
    sourceAppRouteId: string,
    destinationAppRouteId: string,
  ) {
    const requestPayload = {
      functionName: 'copyAssetsAsync',
      method: 'POST',
      parameters: { sourceAppRouteId, destinationAppRouteId },
    }

    return await apiService.cloudFunctionsBase<{
      scheduledFiles: number
    }>(requestPayload)
  }

  /**
   * Upload an image to an Azure repo.
   *
   * @async
   * @param {String} path - The path to upload the file to.
   * @param {File} file - The file to upload.
   * @return  API returns.
   */
  static async uploadImage(path: string, file: File) {
    const body = new FormData()
    body.append('asset', file, file.name)
    body.append('path', path)

    const requestPayload = {
      functionName: 'uploadImage',
      method: 'POST',
      body,
    }

    return await apiService.cloudFunctionsBase<{ path: string }>(requestPayload)
  }

  /**
   * Create a new prescription.
   *
   * @async
   * @param {Object} data - An object with all the values needed to create a prescription.
   * @return return a success with the appprescription id.
   */
  static async createPrescription(data: PrescriptionDataForm) {
    let body: PrescriptionBody = { details: { prescriptionType: 'standard' } }
    const appRouteId = data.configuration.approute.id
    const eraseAndReplace = data.configuration.approute.eraseAndReplace
    const useDefault = data.configuration.approute.useDefault
    const parameters = {
      appRouteId,
      eraseAndReplace,
      useDefault,
    }

    if (
      data?.configuration?.json !== null &&
      data?.configuration?.json !== undefined
    ) {
      // Read json file to treat it as the value we normally send from form.
      body = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          const content = JSON.parse(event.target.result.toString())
          resolve(content)
        }
        reader.onerror = (error) => reject(error)
        reader.readAsText(data.configuration.json)
      })
    } else {
      for (const [key, value] of Object.entries(data)) {
        if (
          ['brands', 'criteria', 'flags'].includes(key) &&
          Array.isArray(value) &&
          value.length > 0
        ) {
          body[key] = value.map((val) =>
            typeof val === 'string' ? val : val.id,
          )
          continue
        }

        if (Array.isArray(value) && value.length > 0) {
          body[key] = value
          continue
        }

        if (key === 'configuration') {
          Object.keys(value).forEach((conf) => {
            const confValue = Object.assign({}, value?.[conf])
            if (confValue.active) {
              delete confValue.active
              if (!body[key]) body[key] = {}
              body[key][conf] = confValue
            }
          })
        }
      }
    }

    body.details = {
      prescriptionType: data.configuration.prescriptionType || 'standard',
      ...(data.maxDesiredProducts && {
        maxDesiredProducts: +data.maxDesiredProducts,
      }),
    }

    const requestPayload = {
      functionName: 'createPrescription',
      method: 'POST',
      parameters,
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const res = await apiService.cloudFunctionsBase<{ message: string }>(
      requestPayload,
    )
    if (!res.success)
      throw new Error(res.message || 'Create Prescription failed')
    Vue.prototype.$store.dispatch('bindAppRoutes')
    return res
  }

  /**
   * Update a  prescription.
   *
   * @async
   * @param {Object} data - An object with all the values needed to edit the prescription.
   * @return return a success.
   */
  static async updatePrescription(
    updatedValues: {
      created?: {
        brands?: string[]
        criterias?: string[]
        flags?: string[]
      }
      deleted?: {
        brands?: string[]
        criterias?: string[]
        flags?: string[]
      }
    },
    appRouteId: string,
  ) {
    const parameters = {
      appRouteId,
    }

    const requestPayload = {
      functionName: 'updatePrescription',
      method: 'POST',
      parameters,
      body: updatedValues,
    }

    const res = await apiService.cloudFunctionsBase(requestPayload)

    if (!res.success) throw new Error('Update Prescription failed')

    return res
  }

  /**
   * Copy a new prescription.
   *
   * @async
   * @param {String} sourceAppRouteId - The source app route id.
   * @param {String} destinationAppRouteId - The destination app route id.
   */
  static async copyPrescription(
    sourceAppRouteId: string,
    destinationAppRouteId: string,
    label = 'Prescription copy',
    includeInactiveProfile = false,
  ) {
    if (!sourceAppRouteId || !destinationAppRouteId)
      throw new Error(
        `Missing ${!sourceAppRouteId ? 'source' : 'destination'} appRouteId`,
      )

    const requestPayload = {
      functionName: 'copyPrescription',
      method: 'GET',
      parameters: {
        destinationAppRouteId,
        sourceAppRouteId,
        includeInactiveProfile,
      },
    }

    const res = await apiService.cloudFunctionsBase<{
      prescriptionPath: string
    }>(requestPayload)

    if (!res.success) throw new Error('Copy Prescription failed')

    Vue.prototype.$store.dispatch('notifications/listenCopyCollection', {
      documentPath: res.prescriptionPath,
      label,
    })

    return res
  }

  /**
   * Delete all profiles of a prescription.
   *
   * @async
   * @param {String} appRouteId - The app route id.
   */
  static async deleteAllPrescriptionProfiles(appRouteId: string) {
    if (!appRouteId)
      throw new Error(
        'Missing source appRouteId for prescription profiles deletion',
      )

    const requestPayload = {
      functionName: 'deleteAllPrescriptionProfiles',
      method: 'DELETE',
      parameters: { appRouteId },
    }

    return await apiService.cloudFunctionsBase<{ error }>(requestPayload)
  }

  //------- Prescription Automation -------

  /**
   *
   * @async
   * @param {String} appRouteId - The app route id.
   * @param {PrescriptionAutoExecutionBody} prescriptionAutoExecutionBody - The prescription
   */
  static async prescriptionAutomationExecution(
    appRouteId: string,
    prescriptionAutoExecutionBody: PrescriptionAutoExecutionBody,
  ) {
    if (!appRouteId)
      throw new Error(
        'Missing source appRouteId for prescription automation execution',
      )

    const requestPayload = {
      functionName: 'prescriptionAutomation/execution',
      method: 'POST',
      parameters: { appRouteId },
      body: prescriptionAutoExecutionBody,
    }

    return await apiService.cloudFunctionsBase(requestPayload)
  }

  /**
   * Get the profiles with items in the passed steps.
   *
   * @async
   * @param {String} appRouteId - The app route id.
   * @param {Array<String>} steps - The steps ids to look for items.
   */
  static async getProfilesFromSteps(appRouteId: string, stepsIds: string[]) {
    if (!appRouteId)
      throw new Error(
        'Missing source appRouteId for prescription profiles deletion',
      )
    if (!Array.isArray(stepsIds) || stepsIds.length === 0)
      throw new Error('No stepsIds parameter to look for')

    const requestPayload = {
      functionName: 'getProfilesFromSteps',
      method: 'GET',
      parameters: { appRouteId, stepsIds },
    }

    return await apiService.cloudFunctionsBase<
      {
        profilesInSteps: {
          profileId: string
          totalItemsInSteps: number
          stepsWithItems: string[]
        }
      }[]
    >(requestPayload)
  }

  /**
   * Delete the items associated to the specified steps and delete the steps and event
   *
   * @async
   * @param {String} appRouteId - The app route id.
   * @param {Array<String>} steps - The steps ids to look for items.
   */
  static async deleteEventSteps(
    appRouteId: string,
    stepsIds: string[],
    eventId = '',
  ) {
    if (!appRouteId)
      throw new Error(
        'Missing source appRouteId for prescription profiles deletion',
      )
    if (!Array.isArray(stepsIds) || stepsIds.length === 0)
      throw new Error('No steps parameters to look for')

    const requestPayload = {
      functionName: 'deleteEventSteps',
      method: 'POST',
      parameters: { appRouteId },
      body: {
        eventId,
        stepsIds,
      },
    }

    return await apiService.cloudFunctionsBase<{ itemsDeleted: boolean }>(
      requestPayload,
    )
  }

  /**
   * Migrate a json to an instance.
   *
   * @async
   * @param {String} destinationAppRouteId - The destination app instance id.
   * @param {Object} collectionsData - An object with all collections data needed.
   * @param {String} updateBehavior - The update behavior for
   *
   */
  static async migrateRouteWithJson(
    destinationAppRouteId: string,
    collectionsData: Collections,
    updateBehavior = 'completion',
  ) {
    if (!destinationAppRouteId)
      throw new Error('Missing destination app instance id.')
    if (
      !collectionsData ||
      Object.keys(collectionsData).every((collectionId) =>
        [null, undefined].includes(collectionsData[collectionId]),
      )
    )
      throw new Error('Missing new collections data.')

    const body = Object.keys(collectionsData).reduce((acc, collectionId) => {
      if (!collectionsData[collectionId]) return acc
      acc[collectionId] = collectionsData[collectionId]
      return acc
    }, {})

    const requestPayload = {
      functionName: 'migrateRoute/migrateRouteWithJson',
      method: 'POST',
      parameters: { destinationAppRouteId, updateBehavior },
      body,
    }

    return await apiService.cloudFunctionsBase(requestPayload)
  }

  /**
   * Migrate an instance to another.
   *
   * @async
   * @param {String} sourceAppRouteId - The source app instance id.
   * @param {String} destinationAppRouteId - The destination app instance id.
   * @param {String} sourceVersionId - The source version id.
   * @param {String} updateBehavior - The update behavior for
   */
  static async migrateRoute({
    destinationAppRouteId,
    updateBehavior = 'completion',
    sourceAppRouteId = null,
    sourceVersionId = null,
  }: {
    destinationAppRouteId: string
    updateBehavior?: 'completion' | 'copy'
    sourceAppRouteId?: string
    sourceVersionId?: string
  }) {
    if (!destinationAppRouteId)
      throw new Error('Missing destination appRouteId')
    if (sourceAppRouteId === destinationAppRouteId)
      throw new Error("You can't migrate the same instance to itself.")
    if (!sourceAppRouteId && !sourceVersionId)
      throw new Error('Missing source version id or source app instance id')

    const requestPayload = {
      functionName: 'migrateRoute/migrateRoute',
      method: 'GET',
      parameters: {
        ...(sourceAppRouteId && { sourceAppRouteId }),
        ...(sourceVersionId && { sourceVersionId }),
        destinationAppRouteId,
        updateBehavior,
      },
    }

    return await apiService.cloudFunctionsBase(requestPayload)
  }

  //
  // ZIP Functions
  //

  /**
   * Call the function to create a zip file and format the response.
   * @param {String} functionName
   * @param {Object} parameters
   */
  static async callZipCloudFunction(functionName: string, parameters: any) {
    const requestPayload = {
      functionName,
      method: 'GET',
      parameters,
    }

    const response = await apiService.cloudFunctionsBase<Response>(
      requestPayload,
    )
    const url = URL.createObjectURL(await response.blob()) ?? ''
    const filename =
      response.headers
        .get('content-disposition')
        ?.match(/filename=(.*)$/)?.[1] ?? ''

    return {
      url,
      filename,
    }
  }

  /**
   * Export an instance as a json.
   *
   * @async
   * @param {String} appRouteId - The source app route id.
   * @param {String} collectionIds - the collection ids.}
   */
  static async exportRouteData(appRouteId: string, collectionIds: string[]) {
    if (!appRouteId) throw new Error('Missing appRouteId')
    if (!Array.isArray(collectionIds) || collectionIds.length === 0)
      throw new Error('No collection id')

    return await Functions.callZipCloudFunction('exportRouteData', {
      appRouteId,
      collectionIds,
    })
  }

  /**
   * Export an instance as a json.
   *
   * @async
   * @param {String} appVersionId - appVersion id.
   */
  static async exportVersionData(appVersionId) {
    if (!appVersionId) throw new Error('Missing appVersionId')

    return await Functions.callZipCloudFunction('exportVersionData', {
      appVersionId,
    })
  }

  /**
   * Compare two instances and their subcollections recursively.
   *
   * @async
   * @param {String} sourceAppRouteId - The source app instance id.
   * @param {String} destinationAppRouteId - The destination app instance id.
   * @param {String} sourceVersionId - The source app version id.
   */
  static async compareRoutes(
    sourceAppRouteId: string,
    destinationAppRouteId: string,
    sourceVersionId: string,
  ) {
    if (!destinationAppRouteId)
      throw new Error('Missing destination appRouteId')
    if (!sourceAppRouteId && !sourceVersionId)
      throw new Error('Missing version Id to use default content')

    const requestPayload = {
      functionName: 'compareRoutes/compareRoutes',
      method: 'GET',
      parameters: {
        ...(sourceAppRouteId && { sourceAppRouteId }),
        destinationAppRouteId,
        ...(sourceVersionId && !sourceAppRouteId && { sourceVersionId }),
      },
    }

    return await apiService.cloudFunctionsBase<{
      source: string | null
      destination: string
      collectionId?: string
      comparison: CompareRoutesResult
    }>(requestPayload)
  }

  /**
   * Compare an instance with JSON data given.
   *
   * @async
   * @param {String} destinationAppRouteId - The destination app instance id.
   * @param {Object} collectionsData - The Json data for each collections.
   */
  static async compareJsonWithRoute(
    destinationAppRouteId: string,
    collectionsData: Collections,
  ) {
    if (!destinationAppRouteId)
      throw new Error('Missing destination appRouteId.')
    if (!collectionsData) throw new Error('Missing collections data.')

    const filteredCollectionsData = Object.keys(collectionsData).reduce(
      (accumulator, collectionName) => {
        const collectionData = collectionsData[collectionName]
        if (collectionData) {
          accumulator[collectionName] = collectionData
        }
        return accumulator
      },
      {},
    )

    const requestPayload = {
      functionName: 'compareRoutes/compareJsonWithRoute',
      method: 'POST',
      parameters: { destinationAppRouteId },
      body: filteredCollectionsData,
    }

    return await apiService.cloudFunctionsBase<{
      source: string | null
      destination: string
      collectionId?: string
      comparison: CompareRoutesResult
    }>(requestPayload)
  }

  /**
   * Duplicates a document to a given destination (optional) or to the same collection as the source.
   *
   * @async
   * @param {String} sourcePath - The source document path.
   * @param {String} destinationPath - The destination path (optional).
   */
  static async duplicateDocument(sourcePath: string, destinationPath?: string) {
    if (!sourcePath) throw new Error('Missing source path')
    if (!destinationPath)
      destinationPath = sourcePath.substring(0, sourcePath.lastIndexOf('/'))

    const requestPayload = {
      functionName: 'duplicateDocument',
      method: 'GET',
      parameters: { sourcePath, destinationPath },
    }

    return await apiService.cloudFunctionsBase<DocumentResult>(requestPayload)
  }

  /**
   * Reset all hits counts of each profiles.
   *
   * @async
   * @param {String} appRouteId - The appRoute id to get the info from.
   */
  static async resetProfilesHit(appRouteId: string) {
    if (!appRouteId) throw new Error('Missing appRouteId parameter.')

    const requestPayload = {
      functionName: 'resetProfilesHit',
      method: 'GET',
      parameters: { appRouteId },
    }

    return await apiService.cloudFunctionsBase<{ count: number }>(
      requestPayload,
    )
  }

  /**
   * Creates a new approute.
   *
   * @async
   * @param {Object} routeDetails - The object with the information of the route.
   * @return The new route id and response information.
   */
  static async createRoute(routeDetails: {
    app: string
    country: string
    customer: string
    env: string
    tenant: string
    touchpoint: string
    type: string
    version: string
    collections: {
      appcontent?: any
      appconfigurations?: any
      appresources?: any
      appcustomisations?: any
    }
    copyFrom?: string
  }) {
    if (!routeDetails) throw new Error('Route details not set')

    const requestPayload = {
      functionName: 'createRoute',
      method: 'POST',
      body: routeDetails,
    }

    // Route creation.
    const createdRouteRes = await apiService.cloudFunctionsBase<{
      routeId: string
    }>(requestPayload)

    if (!createdRouteRes.success) throw new Error('Route creation failed')

    Vue.prototype.$store.dispatch('bindAppRoutes')

    return createdRouteRes.routeId
  }

  /**
   * Save Document.
   *
   * @param {String} appRouteId - The add route id.
   * @param {object} body - collections
   * @return API returns.
   */
  static async saveDoc(
    appRouteId: string,
    body: { collections: Collections; fileChanges: FileChange[] },
  ) {
    if (!appRouteId) throw new Error('Missing appRouteId')
    if (!body) throw new Error('Missing body')

    const requestPayload = {
      functionName: 'saveRoute',
      method: 'POST',
      parameters: { appRouteId },
      body,
    }

    const res = await apiService.cloudFunctionsBase(requestPayload)
    if (!res.success) throw res
    return res
  }

  /**
   * Deploy approute to production.
   * @param {String} appRouteId
   * @param {Boolean} acceptDiffVersion
   */
  static async deployProdInstance(
    appRouteId: string,
    acceptDiffVersion: boolean,
  ) {
    if (!appRouteId) throw new Error('Missing appRouteId parameter.')

    const requestPayload = {
      functionName: 'deployProd',
      method: 'POST',
      parameters: { appRouteId, acceptDiffVersion: !!acceptDiffVersion },
    }

    const res = await apiService.cloudFunctionsBase<{
      prescriptionPath: string
    }>(requestPayload)

    if (!res.success) {
      throw res
    }

    if (res.prescriptionPath) {
      Vue.prototype.$store.dispatch('notifications/listenCopyCollection', {
        documentPath: res.prescriptionPath,
        label: 'Deployment to production',
      })
    }

    return res
  }

  static async getRoutesScoped() {
    const requestPayload = {
      functionName: 'getRoutesScoped',
      method: 'GET',
    }

    return apiService.cloudFunctionsBase<{
      routes: {
        id: string
        application: string
        version: string
        country: string
        customer: string
        environment: string
        tenant: string
        touchpoint: string
        type: string
        meta: {
          active: boolean
          subcollections: string[]
        }
      }[]
    }>(requestPayload)
  }

  //PRODUCT API CONFIGURATIONS

  static async getProductConfigurations(env: 'staging' | 'production') {
    const requestPayload = {
      functionName: 'productApi/' + env + '/configurations',
      method: 'GET',
    }

    return apiService.cloudFunctionsBase<{
      configurations: any
      timestamp: string
    }>(requestPayload)
  }

  static async getProductConfiguration(
    env: 'staging' | 'production',
    customerId: string,
    countryCode: string,
  ) {
    const requestPayload = {
      functionName:
        'productApi/' +
        env +
        '/configuration/' +
        customerId +
        '/' +
        countryCode,
      method: 'GET',
    }

    return apiService.cloudFunctionsBase<{
      configuration: any
      timestamp: string
    }>(requestPayload)
  }

  static async createProductConfiguration(
    env: 'staging' | 'production',
    customerId: string,
    countryCode: string,
    body: any,
  ) {
    const requestPayload = {
      functionName:
        'productApi/' +
        env +
        '/configuration/' +
        customerId +
        '/' +
        countryCode,
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase(requestPayload)

    if (!res.success) throw new Error(res.message)
  }

  static async updateProductConfiguration(
    env: 'staging' | 'production',
    customerId: string,
    countryCode: string,
    body: any,
  ) {
    const requestPayload = {
      functionName:
        'productApi/' +
        env +
        '/configuration/' +
        customerId +
        '/' +
        countryCode,
      method: 'PATCH',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const res = await apiService.cloudFunctionsBase(requestPayload)

    if (!res.success) throw new Error(res.message)
  }

  static async deleteProductConfiguration(
    env: 'staging' | 'production',
    customerId: string,
    countryCode: string,
  ) {
    const requestPayload = {
      functionName:
        'productApi/' +
        env +
        '/configuration/' +
        customerId +
        '/' +
        countryCode,
      method: 'DELETE',
    }

    const res = await apiService.cloudFunctionsBase(requestPayload)

    if (!res.success) throw new Error(res.message)
  }

  static async getProfilesByItems(appRouteId: string) {
    const requestPayload = {
      functionName: 'getProfilesByItems',
      method: 'GET',
      parameters: { appRouteId },
    }

    const res = await apiService.cloudFunctionsBase<ProfilesByItemsResponse>(
      requestPayload,
    )

    if (!res.success) throw new Error(res.message)

    return res.results
  }

  static async searchReplaceItem(
    appRouteId: string,
    itemId: string,
    newItemId?: string,
  ) {
    const requestPayload = {
      functionName: 'searchReplaceItem',
      method: 'POST',
      parameters: { appRouteId },
      body: {
        itemId,
        newItemId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const res = await apiService.cloudFunctionsBase<{ count: number }>(
      requestPayload,
    )

    if (!res.success) throw new Error(res.message)

    return res.count
  }

  static async searchProducts(
    appRouteId: string,
    params: SearchProductsParams | undefined = {},
  ): Promise<
    HttpResponseStandard & {
      results: any[]
      pagination: SearchProductsPagination
    }
  > {
    const requestPayload = {
      functionName: 'productApi/products',
      method: 'GET',
      parameters: {
        ...params,
        appRouteId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{
      results: any[]
      pagination: SearchProductsPagination
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res
  }

  //With PRODUCT API V3
  static async searchProductsV3(
    appRouteId: string,
    params: SearchProductsParams | undefined = {}, //TODO update for V3
  ): Promise<any> {
    const paginationParams = {
      ...params,
      ...(params.recordByPage && { pageSize: params.recordByPage }),
    }

    const requestPayload = {
      functionName: `approutes/${appRouteId}/products`,
      method: 'GET',
      parameters: {
        ...paginationParams,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase(requestPayload)
    if (!res.success) throw new Error(res.message)

    return res
  }

  static async getAllActiveProductsForInstance(
    appRouteId: string,
  ): Promise<any> {
    const activeParameters = {
      isActive: true,
    }
    const requestPayload = {
      functionName: `approutes/${appRouteId}/products`,
      method: 'GET',
      parameters: activeParameters,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase(requestPayload)
    if (!res.success) throw new Error(res.message)

    const paginationParams = {
      ...activeParameters,
      pageSize: (res as any).pagination.nbResults,
    }

    const fullRequestPayload = {
      functionName: `approutes/${appRouteId}/products`,
      method: 'GET',
      parameters: {
        ...paginationParams,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const fullRes = await apiService.cloudFunctionsBase(fullRequestPayload)
    if (!fullRes.success) throw new Error(res.message)

    return fullRes
  }
  // TODO: store-mehdi why not used?
  static async getCatalogProductsNotInInstance(
    appRouteId: string,
    catalogConnectorId: string,
    params: SearchProductsParams | undefined = {}, //TODO update for V3
  ): Promise<any> {
    const paginationParams = {
      ...(params.page && { page: params.page }),
      recordByPage: params.recordByPage,
      ...(params.search && { search: params.search }),
      ...(params.sort && { sort: params.sort }),
      ...(params.direction && { direction: params.direction }),
    }

    const requestPayload = {
      functionName: `productApi/catalog-connectors/${catalogConnectorId}/approutes/${appRouteId}/products`,
      parameters: {
        ...paginationParams,
      },
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase(requestPayload)
    if (!res.success) throw new Error(res.message)

    return res
  }

  static async updateCatalogConnectorById(
    tenant: BeautyHubTenant,
    catalogConnectorId: string,
    body: any,
  ) {
    const requestPayload = {
      functionName: `productApi/catalog-connectors/${tenant}/${catalogConnectorId}`,
      method: 'PATCH',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
      response: any
    }>(requestPayload)

    if (!res.success) {
      switch (res.response.status) {
        case 422:
          if (res.response.errors[0].code === 'CC_006') {
            throw new Error(
              'TestSKU is required when updating fields used to target the CustomerAPI.',
            )
          }
          throw new Error(res.response.errors[0].message)
          break
        case 400:
          throw new Error(
            res.response.errors
              ? 'Errors : ' +
                Object.entries(res.response.errors)
                  .map((key) => `${key[0].split('.').pop()}`)
                  .join(', ')
              : 'Somes fileds are incorrect or missing.',
          )
        default:
          throw new Error('An error occurred. Please, try again !')
      }
    }

    return res
  }
  
  static async bulkActionsProductsForLocalCatalog(
    tenant: BeautyHubTenant,
    catalogConnectorId: string,
    payload: BulkProductsPayload,
  ) {
    const requestPayload = {
      functionName: `productApi/catalog-connectors/${tenant}/${catalogConnectorId}/products/bulk`,
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{ results: any[] }>(
      requestPayload,
    )

    return res
  }

  static async bulkActionsProductLayers(
    appRouteId: string,
    payload: Partial<BulkProductsPayload>,
  ) {
    const requestPayload = {
      functionName: `approutes/${appRouteId}/products/bulk`,
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{ results: any[] }>(
      requestPayload,
    )

    return res
  }

  static async addListOfSkus(
    tenant: BeautyHubTenant,
    catalogConnectorId: string,
    payload: { skus: string[] },
  ) {
    const requestPayload = {
      functionName: `productApi/catalog-connectors/${tenant}/${catalogConnectorId}/skus`,
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{ success: boolean, status: string, successes: Array<any>, errors: Array<any> }>(
      requestPayload,
    )

    //if (!res.success) throw new Error(res.message)

    return res
  }

  static async deleteSkusFromReferential(
    tenant: BeautyHubTenant,
    catalogConnectorId: string,
    payload: { skus: string[] },
  ) {
    const requestPayload = {
      functionName: `productApi/catalog-connectors/${tenant}/${catalogConnectorId}/skus`,
      method: 'DELETE',
      body: payload,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{
      results: any[]
      errors: any[]
    }>(requestPayload)

    return res
  }

  static async getProductDetailsV3(
    appRouteId: string,
    sku: string,
  ): Promise<InstanceProductResponse> {
    const requestPayload = {
      functionName: `approutes/${appRouteId}/products/${sku}`, ///:routeId/products/:productId
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const res = await apiService.cloudFunctionsBase<{
      response: { productLayer: BHProductDTO; catalogProduct: BHProductDTO }
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res.response
  }

  static async getProductDetails(
    appRouteId: string,
    sku: string,
    params?: GetProductDetailsParams,
  ): Promise<HttpResponseStandard & { results: BHProductDTO }> {
    const requestPayload = {
      functionName: `productApi/products/${sku}`,
      method: 'GET',
      parameters: {
        appRouteId,
        ...params,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{ results: BHProductDTO }>(
      requestPayload,
    )

    if (!res.success) throw new Error(res.message)

    return res
  }

  // TODO: mock the return format as if it is with the new interface contract
  static async getPrivateProductDetails(
    appRouteId: string,
    sku: string,
    params?: GetProductDetailsParams,
  ): Promise<PrivateProductDetails> {
    const { results } = await this.getProductDetails(appRouteId, sku, params)

    return {
      base: results,
      overriden: {},
      merged: results,
    }
  }

  static async getProductsByIds(
    appRouteId: string,
    params: GetProductsByIdsParams,
  ): Promise<HttpResponseStandard & { results: GetProductsByIdsResult }> {
    const requestPayload = {
      functionName: 'productApi/products/by-ids',
      method: 'GET',
      parameters: {
        ...params,
        appRouteId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const res = await apiService.cloudFunctionsBase<{
      results: ProductsByIdsResult
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return {
      ...res,
      results: {
        found: res.results?.products,
        notFound: res.results?.productsSKUsNotFound,
      },
    }
  }

  static async getProductsStats(appRouteId: string): Promise<ProductStats> {
    const requestPayload = {
      functionName: 'productApi/products/stats',
      method: 'GET',
      parameters: {
        appRouteId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{
      results: ProductStats
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res.results
  }

  static async findLooks(
    appRouteId: string,
    params?: FindLooksParams,
  ): Promise<LookDataDto> {
    const requestPayload = {
      functionName: 'lookApi/looks',
      method: 'GET',
      parameters: {
        ...params,
        appRouteId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<LookDataDto>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res
  }

  static async exportProfilesCSV(appRouteId: string): Promise<string> {
    const requestPayload = {
      functionName: 'exportProfilesCSV',
      method: 'GET',
      parameters: {
        appRouteId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
      value: string
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res.value
  }

  static async getBrandLogos(brandId: string): Promise<BrandLogoParams[]> {
    const requestPayload = {
      functionName: `brandApi/${brandId}/logos`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
      value: BrandLogoParams[]
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res.value
  }

  static async createBrandLogo(
    brandId: string,
    countryId: string,
    customerId: string,
    customLogo: string,
  ): Promise<boolean> {
    const requestPayload = {
      functionName: `brandApi/${brandId}/logos`,
      method: 'POST',
      parameters: { countryId, customerId, customLogo },
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res.success
  }

  static async deleteBrandLogo(
    brandId: string,
    countryId: string,
    customerId: string,
  ): Promise<boolean> {
    const requestPayload = {
      functionName: `brandApi/${brandId}/logos/${customerId}-${countryId}`,
      method: 'DELETE',
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res.success
  }

  static async getCatalogs(
    tenant: BeautyHubTenant,
    customerCodes?: string[],
  ): Promise<CatalogConnector[]> {
    const requestPayload = {
      functionName: `productApi/catalog-connectors/${tenant}`,
      method: 'GET',
      parameters: {
        pageSize: 50,
        ...(customerCodes && { customerCodes: customerCodes.join(',') }),
      },
    }
    let res = await apiService.cloudFunctionsBase<BHPagination>(requestPayload)
    if (!res.success) throw new Error(res.message)

    //following code is to get all the catalogs without pageSize limitation
    if (res.hasMore) {
      requestPayload.parameters = {
        ...requestPayload.parameters,
        pageSize: res.totalCount,
      }
      res = await apiService.cloudFunctionsBase<BHPagination>(requestPayload)
    }

    return res.items
  }

  static async getCatalogById(
    catalogConnectorId: string,
    tenant: BeautyHubTenant,
  ): Promise<CatalogConnector> {
    const requestPayload = {
      functionName: `productApi/catalog-connectors/${tenant}/${catalogConnectorId}`,
      method: 'GET',
    }
    const res = await apiService.cloudFunctionsBase<{
      catalogConnector: any
    }>(requestPayload)
    return res.catalogConnector
  }

  static async getProductsByCatalog(
    catalogConnectorId: string,
    tenant: BeautyHubTenant,
    payload: any,
  ): Promise<GetProductsByCatalogResult> {
    const requestPayload = {
      functionName: `productApi/catalog-connectors/${tenant}/${catalogConnectorId}/products`,
      method: 'GET',
      parameters: {
        ...payload,
      },
    }
    const res = await apiService.cloudFunctionsBase(requestPayload)

    if (!res.success) {
      throw new Error(res.message)
    }

    const data = GetProductsByCatalogResultSchema.parse(res)
    return data
  }

  static async getRouteCatalogSettings(routeId: string): Promise<any> {
    const requestPayload = {
      functionName: `approutes/${routeId}/settings`,
      method: 'GET',
    }
    const res = await apiService.cloudFunctionsBase(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res
  }

  static async associateCatalogToRouteId(
    routeId: string,
    catalog:
      | CatalogConfigurationWithBrandApi
      | CatalogConfigurationWithoutBrandApi
      | string,
    selectedItZone: string,
    isUpdate = false,
    copyMissingSkusToNewCatalog = true,
  ): Promise<boolean> {
    const requestPayload = {
      functionName: `approutes/${routeId}/settings`,
      method: isUpdate ? 'PATCH' : 'POST',
      body: { catalog, selectedItZone, copyMissingSkusToNewCatalog },
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
      response: any
    }>(requestPayload)

    if (!res.success) {
      switch (res.response.status) {
        case 422:
          if (res.response.errors[0].code === 'CC_004') {
            throw new Error(
              'Unable to connect to the catalog, please ckeck your URL and credentials.',
            )
          }
          throw new Error(res.response.errors[0].message)
          break
        case 400:
          throw new Error(
            res.response.errors
              ? 'Errors : ' +
                Object.entries(res.response.errors)
                  .map((key) => `${key[0].split('.').pop()}`)
                  .join(', ')
              : 'Customer, country or brand are incorrect or missing.',
          )
        default:
          throw new Error('An error occurred. Please, try again !')
      }
    }

    return res.response
  }
  static async runMigration(routeId: string): Promise<boolean> {
    const requestPayload = {
      functionName: `approutes/${routeId}/migrate`,
      method: 'POST',
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
      response: any
    }>(requestPayload)

    if (!res.success) {
      throw new Error(res.message)
    }

    return res.response
  }

  static async getProductInstance(
    routeId: string,
  ): Promise<ProductInstanceInfos> {
    const requestPayload = {
      functionName: `approutes/${routeId}/settings`,
      method: 'GET',
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
      response: any
    }>(requestPayload)

    if (!res.success) {
      throw new Error(res.response?.errors[0]?.message || res.message)
    }

    return res.response
  }

  static async getProductFromCatalog(
    tenant: BeautyHubTenant,
    catalogConnectorId: string,
    sku: string,
  ): Promise<BHProductDTO> {
    const requestPayload = {
      functionName: `productApi/catalog-connectors/${tenant}/${catalogConnectorId}/products/${sku}`,
      method: 'GET',
    }
    const res = await apiService.cloudFunctionsBase<{
      success: boolean
      response: BHProductDTO
    }>(requestPayload)

    if (!res.success) throw new Error(res.message)

    return res.response
  }
}

type PrescriptionBody = {
  steps?: (
    | string
    | {
        matchings?: string[] | undefined
        id: string
        label: string
      }
  )[]
  brands?: string[]
  criteria?: string[]
  events?: (
    | string
    | {
        matchings?: string[] | undefined
        id: string
        label: string
      }
  )[]
  flags?: string[]
  eventsStepsStructure?: {
    eventId: string
    steps: {
      stepId: string
    }[]
  }[]
  configuration?: {
    productApi?: {
      brand_code: string
      country_code: string
    }
    airtableApi?: {
      product?: boolean | undefined
      look?: boolean | undefined
      airtableId: string
    }
    lookApi?: {
      brandHashId: string
      service: string
      cmsEnvironment: string
      countryCode: string
      language: string
      platform: string
    }
  }
  details: {
    maxDesiredProducts?: number | undefined
    prescriptionType: 'standard' | 'ai-powered'
  }
}

type CompareRoutesResult = {
  numberOfChanges: number
  extraCollectionsInSource: string[]
  extraCollectionsInDestination: string[]
  childCollections: CompareRoutesResult[]
  extraKeysInSource: string[]
  extraKeysInDestination: string[]
  dataDifferences: Record<string, any>
}

export type ProductsByIdsResult = {
  products: BHProductDTO[]
  productsSKUsNotFound: string[]
}

type GetProductsByIdsResult = {
  found: BHProductDTO[]
  notFound: string[]
}

type PrescriptionAutoExecutionBody = {
  productApi: {
    forceItemBrandCode: boolean
    headers?: {
      api_version: string
    }
    params: {
      brand_code: string
      country_code: string
      description_overridable: boolean
      name_overridable: boolean
    }
    url: string
  }
}

export type SearchProductsParams = {
  search?: string
  sort?: 'none' | 'sku' | 'name' | 'isActive' | 'status'
  direction?: 'asc' | 'desc'
  page?: number
  recordByPage?: number
  isFound?: boolean
  isExist?: boolean
  isCalibrated?: boolean
  isActiveWithoutCalibration?: boolean
  excludeInstanceProducts?: string
}

export type SearchProductsPagination = {
  nbResults: number
  nbPages: number
  currentPage: number
}

type GetProductDetailsParams = {
  description_overridable: boolean
  name_overridable: boolean
  disable_computename: boolean
}

type GetProductsByIdsParams = {
  ids?: string[]
}

type FindLooksParams = {
  sortBy?: 'look_id' | 'look_uid' | 'look_name' | 'look_family'
  sortByOrder?: 'asc' | 'desc'
  filterBy?: string
  language?: string
}

export type InstanceProductResponse = {
  catalogProduct: BHProductDTO
  productLayer: BHProductDTO
}

export type PrivateProductDetails = {
  base: BHProductDTO
  overriden: Partial<BHProductDTO>
  merged: BHProductDTO
}

export type ProfilesByItemsResponse = {
  success: boolean
  results: ProfilesByItems
  message?: string
}

export type ProfileInfo = { path: string; profileId: string }

export type ProfilesByItems = Record<string, ProfileInfo[]>

export type BrandLogoParams = {
  id: string
  countryId: string
  customerId: string
  customLogo: string
}

export interface BHPagination {
  success: boolean
  totalCount: number
  currentCount: number
  hasMore: boolean
  items: CatalogConnector[]
  page: number
  pageSize: number
}

interface ProductInstanceInfos {
  allowUnselectedProduct: boolean
  catalogConnector: {
    id: string
    name: string
    apiUri: string
    [key: string]: any // Additional properties if any
  }
  countryCode: string
  createdAt: string
  customerCode: string
  fallbackOnCustomerAPI: boolean
  id: string
  service: string
  touchpoint: string
  type: string
}
