export const templates = [
  'NGLORA',
  'WSF',
  'DSF',
  'Baozun',
  'Salesforce',
  'Magento',
  'VTEX',
  'Shopee',
]

export const authenticationTypes = [
  { text: 'Anonymous', value: 'anonymous' },
  { text: 'Api Key', value: 'apikey' },
  { text: 'Bearer', value: 'bearer' },
  { text: 'Basic', value: 'basic' },
]

export const ENVIRONMENTS = [
  { id: 'staging', name: 'Staging' },
  { id: 'production', name: 'Production' },
]
export interface CatalogWithBrandApi {
  environment: string
  selectedItZone: string
  catalogName: string
  country: string
  customer: string
  template: string
  apiUri: string
  query: string
  authMode: string
  username: string
  password: string
  headerName: string
  token: string
  apiKey: string
}
